<template>
  <div class="item-list">
    <a-table
      class="ordertable"
      bordered
      :columns="filteredColumns"
      :data-source="details"
      :rowKey="e => e.id"
      :scroll="{ x: 1150 }"
      :pagination="false"
    >
      <span slot="codeKat" slot-scope="codeKat, record">
        <a-button @click="showRefundDetails(record)">
          {{ codeKat }}
        </a-button>
      </span>

      <span v-if="!isBuyer" slot="supplier" slot-scope="supplier_name">
        <span>{{ supplier_name }}</span>
      </span>

      <span slot="brand" slot-scope="brand, record">
        <span :data-title="record.brand_info" class="tittleHover">
          {{ brand }}
        </span>
      </span>

      <div slot="bcount" slot-scope="text, record">
        <a-input-number
          :min="1"
          :max="record.availability + record.in_transit"
          :default-value="1"
          @change="
            value => {
              onChange(record.id, value)
            }
          "
        />
      </div>

      <template
        v-if="isDealer || isBuyerForDealers"
        slot="clientCode"
        slot-scope="text, record"
      >
        <a-select
          :show-search="true"
          option-filter-prop="children"
          placeholder="Код ДЦ"
          class="selects"
          default-value=""
          style="width: 100%"
          @change="
            value => {
              onClientCodeChange(record.id, value)
            }
          "
        >
          <template
            v-for="code in isBuyerForDealers ? dcClientCodes : clientCodes"
          >
            <a-select-option :key="code.id" :value="code.id">
              {{ code.code }}
            </a-select-option>
          </template>
        </a-select>
      </template>

      <template slot="availableCount" slot-scope="item">
        {{ item.count }}
      </template>

      <template slot="order" slot-scope="text, record">
        <a-button type="primary" ghost @click="handleAddProduct(record)">
          В корзину
        </a-button>
      </template>

      <span slot="priceWithNDS" slot-scope="item">
        <template>{{ item | formatPrice }}</template>
      </span>

      <span slot="coming" slot-scope="coming">{{ coming }}</span>
    </a-table>

    <a-pagination
      class="pagination"
      v-model="currentPage"
      :page-size="paginationOptions.pageSize"
      :total="paginationOptions.totalCount"
    />
    <!--Modal-->
    <a-modal
      v-if="createdCard"
      :visible="visible"
      width="920px"
      title=""
      @ok="handleOk"
      centered
      :maskClosable="false"
      :footer="null"
      @cancel="hideModal"
    >
      <template>
        <h3>Карточка товара</h3>
        <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-input type="hidden" :value="createdCard.id" />
          <a-form-item label="Каталожный номер">
            <a-input
              :disabled="true"
              placeholder="Каталожный номер"
              :value="createdCard.code"
            />
          </a-form-item>
          <a-form-item label="Наименование товара">
            <a-input
              :disabled="true"
              placeholder="Наименование товара"
              :value="createdCard.name"
            />
          </a-form-item>
          <a-form-item label="Бренд">
            <a-input
              :disabled="true"
              placeholder="Бренд"
              :value="createdCard.brand"
            />
          </a-form-item>
          <a-form-item label="Производитель">
            <a-input
              v-model="createdCard.manufacturer"
              :disabled="!isDealer"
              placeholder="Производитель"
              :value="createdCard.manufacturer"
            />
          </a-form-item>
          <a-form-item label="Количество в упаковке">
            <a-input
              v-model="createdCard.package_amount"
              :disabled="!isDealer"
              placeholder="Количество в упаковке"
              :value="createdCard.package_amount"
            />
          </a-form-item>
          <a-form-item label="Обьем, литров">
            <a-input
              :disabled="true"
              placeholder="Обьем, литров"
              :value="createdCard.count"
            />
          </a-form-item>
          <a-form-item label="Цена">
            <a-input
              :disabled="true"
              placeholder="Цена"
              :value="createdCard.price"
            />
          </a-form-item>
          <a-form-item label="Характеристики товара">
            <a-input
              v-model="createdCard.description"
              :disabled="!isDealer"
              placeholder="Характеристики товара"
              :value="createdCard.description"
            />
          </a-form-item>
          <a-form-item label="Комментарий">
            <a-input
              :disabled="true"
              placeholder="Комментарий"
              :value="createdCard.comment"
            />
          </a-form-item>
          <a-button class="flex-center">
            <router-link to="/123">
              Ссылка на спецификации и паспорта безопасности
            </router-link>
          </a-button>
          <a-form-item v-if="isDealer" label="Загрузить фотографии">
            <a-upload name="file">
              <a-button class="file-upload">
                <a-icon type="upload" />
                Файл
              </a-button>
            </a-upload>
          </a-form-item>
        </a-form>
        <div v-if="isDealer" class="mt-20 flex-right">
          <a-button v-if="!createdCard">Создать</a-button>
          <a-button v-if="!!createdCard">В архив</a-button>
          <a-button
            class="ml-10"
            v-if="!!createdCard"
            @click="handleSaveProduct()"
          >
            Сохранить
          </a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex"
import moment from "moment"
import { notification } from "ant-design-vue"

export default {
  name: "ItemList",
  data() {
    return {
      counts: {},
      selectedClientCodes: {},
      visible: false,
      createdCard: {
        id: "",
        manufacturer: "",
        package_amount: "",
        description: "",
      },
      defaultPageGetStatus: false,
      brandInfo: `Минимальный объём заказа от одного Дилера на один адрес доставки кратен 4 бочкам масла объёмом 208 литров каждая (любого наименования). При поставке канистр - минимальный заказ от одного Дилера на один адрес доставки равен 1 европаллете, в любой комбинации упаковок 1 и 4 литровых канистр. На европаллете канистр объемом 1 л. - 420 шт, канистр объемом 4 л. -160 шт. 1 упаковка 1 литровых канистр - 12шт. 1 упаковка 4 литровых канистр - 5шт.`,
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      columns: "details/columns",
      details: "details/details",
      paginationOptions: "details/paginationOptions",
      isOwner: "auth/isOwner",
      isBuyer: "auth/isBuyer",
      isBuyerForDealers: "auth/isBuyerForDealers",
      isDealer: "auth/isDealer",
      clientCodes: "dealers/manufacturerDealers",
      dcClientCodes: "auth/dcClientCodes",
    }),
    currentPage: {
      get() {
        return this.paginationOptions.currentPage
      },
      set(page) {
        this.setCurrentPage(page)
      },
    },
    filteredColumns() {
      return this.columns.filter(h => h.selected || h.selected === undefined)
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    async currentPage(page) {
      await this.fetchDetails(true)
    },
  },
  filters: {
    date(value) {
      return moment(value).format("DD.MM.YYYY")
    },
    formatPrice(price) {
      const formatter = new Intl.NumberFormat("ru-RU", {
        style: "currency",
        currency: "RUB",
      })
      return formatter.format(parseFloat(price))
    },
  },
  methods: {
    async handleSaveProduct() {
      await this.saveProduct(this.createdCard)
      this.hideModal()
    },
    showRefundDetails(record) {
      this.$router.push(`/products/${record.catalog_product_id}`)
    },
    handleOk() {
      console.log("handleOk")
    },
    hideModal() {
      this.visible = false
      this.item = null
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.item = record
            this.visible = true
          },
        },
      }
    },
    ...mapMutations({
      setColumnsProp: "details/SET_COLUMN_PROP",
      setCurrentPage: "details/SET_CURRENT_PAGE",
      setCount: "details/SET_PRODUCT_COUNT",
    }),
    ...mapActions({
      fetchManufacturerDealers: "dealers/fetchManufacturerDealers",
      fetchDetails: "details/fetchDetails",
      saveProduct: "details/saveProduct",
      addProduct: "basket/addProduct",
    }),
    onChange(id, count) {
      this.counts[id] = count
    },
    onClientCodeChange(id, value) {
      this.selectedClientCodes[id] = value
      console.log("pp", this.selectedClientCodes)
    },
    async handleAddProduct(product) {
      let query
      if (this.isDealer || this.isBuyerForDealers) {
        if (!this.selectedClientCodes[product.id]) {
          notification.open({
            message:
              "Необходимо указать код ДЦ для добавления товара в корзину!",
            description: "",
            duration: 5,
            style: {
              width: "600px",
              marginLeft: `${100 - 600}px`,
            },
          })
          return
        }
        query = {
          product,
          count: this.counts[product.id] ? this.counts[product.id] : 1,
          client_id: this.selectedClientCodes[product.id],
        }
      } else {
        query = {
          product,
          count: this.counts[product.id] ? this.counts[product.id] : 1,
          client_id: this.user.client.id,
        }
      }
      const response = await this.addProduct(query)

      if (response) {
        this.$message.success(
          `Товар ${product.code} ${product.name} добавлен в корзину`
        )
      }
    },
    setTableColumns(isDealer) {
      let column
      for (const index in this.columns) {
        column = this.columns[index]
        if (column.selected !== undefined)
          this.setColumnsProp({ index, key: "selected", value: isDealer })
        if (this.isBuyerForDealers && column.dataIndex === "client_id") {
          this.setColumnsProp({
            index,
            key: "selected",
            value: true,
          })
        }
      }
    },
  },
  mounted() {
    this.setTableColumns(this.isDealer)
    if (this.isDealer) {
      this.fetchManufacturerDealers(this.user.dealer.id)
    }
  },
}
</script>

<style lang="scss" scoped>
.ml-10 {
  margin-left: 10px;
}
.flex-right {
  display: flex;
  justify-content: right;
  align-items: center;
}

.mt-20 {
  margin-top: 20px;
}

.ordertable {
  ::v-deep .ant-table-tbody > tr {
    cursor: pointer;
  }
}
.flex {
  display: flex;
  flex-direction: row;
}

.btn {
  margin: 0 5px;
}

.pagination {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}
.ant-form-item {
  margin-bottom: 0;
}
.tittleHover {
  display: inline-block; /* Строчно-блочный элемент */
  position: relative; /* Относительное позиционирование */
}
.tittleHover:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: 95%;
  top: -95%; /* Положение подсказки */
  z-index: 999; /* Отображаем подсказку поверх других элементов */
  background: #fafafa; /* Полупрозрачный цвет фона */
  font-family: Arial, sans-serif; /* Гарнитура шрифта */
  font-size: 11px; /* Размер текста подсказки */
  padding: 5px 10px; /* Поля */
  border: 1px solid #333; /* Параметры рамки */
  width: 900px;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0;
}
</style>
<style>
th.column-action,
td.column-action {
  text-align: center !important;
}
th.column-width-250,
td.column-width-250 {
  width: 180px;
}
th.column-width-120,
td.column-width-120 {
  width: 120px;
}
th.column-width-82,
td.column-width-82 {
  width: 82px;
}
th.column-width-91,
td.column-width-91 {
  width: 91px;
}
th.column-width-104,
td.column-width-104 {
  width: 107px;
}
.ant-input-number {
  width: 100%;
}
</style>
