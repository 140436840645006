<template>
  <div id="advanced-search">
    <a-tabs type="card" @change="handleReset">
      <a-tab-pane key="1" tab="По Каталожному номеру">
        <a-form
          class="ant-advanced-search-form"
          :form="form"
          name="advanced_search"
          @submit="handleSearch"
        >
          <a-form-item
            label="Поиск (по Каталожному номеру и Наименованию)"
            style="display: block"
          >
            <a-input
              v-decorator="[`code`]"
              placeholder="Каталожный номер или Наименование"
            />
          </a-form-item>
          <a-button type="primary" html-type="submit">
            <a-icon type="search" />
            Искать
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
            Очистить
          </a-button>
          <a-button
            :style="{ marginLeft: '8px' }"
            v-if="isManufacturer_orig"
            type="primary"
            @click="uploadFile()"
          >
            <a-icon type="plus" />
            Закачать из файла
          </a-button>
          <a-button
            :style="{ marginLeft: '8px' }"
            v-if="!isDealer && !isManufacturerOperator"
            type="link"
            @click="downloadTemplate()"
          >
            Скачать шаблон
          </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="handleExport">
            Скачать прайс
          </a-button>
        </a-form>
        <ItemList class="mt-10" />
      </a-tab-pane>
    </a-tabs>
    <products-upload-basket-dialog
      :modal-visible="manufacturer_upload_dialog"
      :file-object="editSubject"
      v-on:update:dialog="dialogUpdated($event)"
    />
    <products-upload-dialog
      :modal-visible="supplier_upload_dialog"
      :file-object="editSubject"
      v-on:update:dialog="dialogUpdated($event)"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex"
import ItemList from "@/components/search-details/ItemList"
import { API_PREFIX } from "@/services/api/v1/const"
import ProductsUploadBasketDialog from "@/components/products/ProductsUploadBasketDialog.vue"
import ProductsUploadDialog from "@/components/products/ProductsUploadDialog.vue"
import emptyFile from "@/utils/empty/file"
import api from "@/utils/api"

export default {
  name: "Search",
  components: {
    ItemList,
    ProductsUploadBasketDialog,
    ProductsUploadDialog,
  },
  data() {
    return {
      supplier_upload_dialog: false,
      manufacturer_upload_dialog: false,
      editSubject: this.emptyFileObject(),
      expand: false,
      form: this.$form.createForm(this, { name: "advanced_search" }),
      headers: null,
      apiUrl: `${API_PREFIX}/product/search`,
      fileList: [],
      detailListByFile: [],
      columns: [
        {
          title: "№",
          dataIndex: "numder",
          key: "numder",
        },
        {
          title: "Код дилера",
          key: "connectedUlAmount",
          dataIndex: "connectedUlAmount",
        },
        {
          title: "Каталожный номер",
          dataIndex: "activeUlAmount",
          key: "activeUlAmount",
        },
        {
          title: "Количество, шт",
          dataIndex: "lines",
          key: "lines",
        },
        {
          title: "Заказ",
          key: "order",
          scopedSlots: { customRender: "order" },
        },
      ],
    }
  },
  mounted() {
    this.filterDetails()
    this.setRequestParams()
  },
  computed: {
    ...mapGetters({
      details: "details/details",
      isManufacturer_orig: "auth/isManufacturer",
      // ????
      isManufacturer: "auth/isDealer",
      isManufacturerOperator: "auth/isManufacturerOperator",
      isSupplier: "auth/isOwner",
      isDealer: "auth/isBuyer",
    }),
    count() {
      return this.expand ? 5 : 3
    },
  },
  methods: {
    ...mapMutations({
      clearDetails: "details/CLEAR_DETAILS_AND_FILTERS",
      setFilterCode: "details/SET_CODE_FILTER",
      setPaginationTotalCount: "details/SET_DETAILS_TOTAL_COUNT",
    }),
    ...mapActions({
      filterDetails: "details/fetchDetails",
      setDetails: "details/setDetails",
    }),

    uploadFile() {
      if (this.isManufacturer) this.editManufacturerFile(this.emptyFileObject())
      if (this.isSupplier) this.editSupplierFile(this.emptyFileObject())
    },
    editSupplierFile(fileObject) {
      this.editSubject = fileObject
      this.supplier_upload_dialog = true
    },
    editManufacturerFile(fileObject) {
      this.editSubject = fileObject
      this.manufacturer_upload_dialog = true
    },
    dialogUpdated(value) {
      this.supplier_upload_dialog = value
      this.manufacturer_upload_dialog = value
      this.filterDetails()
    },
    emptyFileObject() {
      return emptyFile()
    },

    downloadTemplate() {
      if (this.isManufacturer)
        document.location = `/templates/Шаблон для загрузки заказов за дилеров от Импортера.xlsx`
      if (this.isSupplier)
        document.location = `/templates/Шаблон поставщика.xlsx`
    },
    setRequestParams() {
      const token = localStorage.getItem("token")
      if (token) {
        this.headers = {
          Authorization: `Bearer ${token}`,
        }
      }
    },
    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        if (error) {
          console.log("error", error)
          return
        }

        this.setFilterCode(values.code)
        this.filterDetails()
      })
    },
    handleSearchByFile(e) {
      e.preventDefault()
      if (this.detailListByFile) {
        this.setDetails(this.detailListByFile)
        this.setPaginationTotalCount(this.detailListByFile.length)
      }
    },
    handleChange(info) {
      const status = info.file.status
      if (status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (status === "done") {
        this.$message.success(`Файл успешно загружен.`)
        this.setFilterCode(info.file.response)
        this.filterDetails()
      } else if (status === "error") {
        this.$message.error(`${info.file.name} ошибка загрузки.`)
      } else if (status === "removed") {
        const idx = this.fileList.findIndex(
          item => item.name === info.file.name
        )
        if (idx || idx === 0) {
          this.fileList = this.fileList.filter(
            item => item.name !== info.file.name
          )
        }
      }
    },
    handleReset() {
      this.form.resetFields()
      this.clearDetails()
      this.setFilterCode("")
      this.filterDetails()
      this.fileList = []
    },
    beforeUpload(file) {
      if (!file) return
      const ext = file.name.split(".").pop()
      if (ext === "csv" || ext === "CSV") {
        this.fileList = [...this.fileList, file]
      } else {
        this.$message.error("Можно загрузить файлы только в формате CSV")
        this.fileList = []
      }
    },
    handleExport() {
      api.downloadFileFromPost(`/catalogs/export_excel`, {})
    },
  },
}
</script>
<style scoped>
.df_sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.w-100 {
  width: 100px;
}
.mt-10 {
  margin-top: 10px;
}
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  border-top-left-radius: 0;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
</style>
<style lang="scss">
#advanced-search {
  .ant-tabs-bar {
    background: none;
    margin: 0;
    margin-bottom: -1px;
  }

  .ant-table-column-title {
    color: rgba(0, 0, 0, 0.65);
  }

  tr {
    color: rgba(0, 0, 0, 0.85);
  }

  th {
    border-radius: 0;
  }
}

#search-result {
  background-color: #fff;
  padding: 10px 25px 40px;
  margin-top: 30px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
}
</style>
