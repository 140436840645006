<template>
  <div>
    <Search />
  </div>
</template>

<script>
import Search from "@/components/search-details/Search"

export default {
  name: "SearchDetails",
  components: {
    Search,
  },
}
</script>

<style scoped></style>
